import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_BRASILMAIS_PORT}${process.env.VUE_APP_API_PATH}/brasilmais`;

export default {
    obterCertificado(participanteDto) {
        return axiosJwt.get(`${api}/participantes/certificados?uf=${participanteDto.uf
            }&cnpj=${participanteDto.cnpj
            }&ano=${participanteDto.ano
            }&ciclo=${participanteDto.ciclo}`, {
            responseType: 'blob',
        });
    },
};
