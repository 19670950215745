<template>
    <painel titulo="Brasil Mais - Emissão de Certificados" icone="pi pi-book" :mostrarRefresh="false">
        <erros-box :erros="erros"></erros-box>
        <detalhe titulo="UF" :isForm="true"><Dropdown v-model="uf" :options="estados" optionLabel="text" optionValue="value" /></detalhe>
        <detalhe titulo="CNPJ" :isForm="true"><InputMask v-model="cnpj" mask="99.999.999/9999-99" :unmask="true" /></detalhe>
        <detalhe titulo="Ano" :isForm="true"><Calendar v-model="ano" view="year" dateFormat="yy" :manualInput="false" /></detalhe>
        <detalhe titulo="Ciclo" :isForm="true"><Dropdown v-model="ciclo" :options="ciclos" /></detalhe>
        <Button label="Emitir Certificado" icon="pi pi-check-circle" class="mt-4 p-button-primary" @click="confirmarEmitir()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { estados2 } from '../../../shared/components/estados';
import { required } from '@vuelidate/validators';
import CertificadosService from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar'],

    data() {
        return {
            uf: null,
            cnpj: null,
            ano: null,
            ciclo: null,
            estados: estados2,
            ciclos: ['Ciclo 1', 'Ciclo 2', 'Ciclo 3', 'Ciclo 4', 'Ciclo 5'],
        };
    },

    validations() {
        return {
            uf: { required },
            cnpj: { required },
            ano: { required },
            ciclo: { required },
        };
    },

    methods: {
        confirmarEmitir() {
            this.$confirm.require({
                message: `Tem certeza que deseja emitir o certificado?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.emitir();
                },
            });
        },

        limparForm() {
            this.uf = null;
            this.cnpj = null;
            this.ano = null;
            this.ciclo = null;
        },

        emitir() {
            this.$store.dispatch('addRequest');
            let participanteDto = {
                uf: this.uf,
                cnpj: this.cnpj,
                ano: this.$moment(this.ano).format('YYYY'),
                ciclo: this.ciclo,
            };
            CertificadosService.obterCertificado(participanteDto).then((response) => {
                if (response?.success) {
                    this.$download(response.data, `Certificado_${this.cnpj}.pdf`);
                    this.limparForm();
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Certificados',
                        detail: 'Erro ao realizar download do Certificado',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    created() {
        this.$store.dispatch('trocarMenu');
    },
};
</script>
